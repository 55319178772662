/* src/components/Dashboard/Dashboard.css */

.dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard h1 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.dashboard ul {
  list-style: none;
  padding: 0;
}

.dashboard li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
}

.dashboard li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.dashboard li button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ff0000;
  color: white;
  cursor: pointer;
}

.dashboard li button:hover {
  background-color: #cc0000;
}

.dashboard a {
  display: block;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #4CAF50;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
}

.dashboard a:hover {
  background-color: #45a049;
}
