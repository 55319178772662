/* src/components/shared/FilterForm.css */

.filter-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .filter-form h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .filter-form form {
    display: flex;
    flex-direction: column;
  }
  
  .filter-form label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .filter-form .large-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .filter-form .large-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    resize: vertical;
  }
  
  .filter-form .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .filter-form .button-row .primary-button,
  .filter-form .button-row .cancel-button,
  .filter-form button[type="button"] {
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 1em;
  }
  
  .filter-form .button-row .primary-button {
    background-color: #4CAF50;
  }
  
  .filter-form .button-row .primary-button:hover {
    background-color: #45a049;
  }
  
  .filter-form .button-row .cancel-button {
    background-color: #ff0000;
  }
  
  .filter-form .button-row .cancel-button:hover {
    background-color: #cc0000;
  }
  
  .filter-form button[type="button"] {
    background-color: #007BFF;
  }
  
  .filter-form button[type="button"]:hover {
    background-color: #006BCE;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .parameter {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .parameter:last-child {
    border-bottom: none;
  }
  
  .parameter .parameter-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  .parameter .parameter-buttons .copy-parameter {
    background-color: #008CBA;
  }
  
  .parameter .parameter-buttons .copy-parameter:hover {
    background-color: #007BAA;
  }
  
  .parameter .parameter-buttons .delete-parameter {
    background-color: #ff0000;
  }
  
  .parameter .parameter-buttons .delete-parameter:hover {
    background-color: #cc0000;
  }
  
  .notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .notification.show {
    opacity: 1;
  }
  