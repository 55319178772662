/* src/components/CreateFilter/CreateFilter.css */
/* src/components/EditFilter/EditFilter.css */

.parameter {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.parameter label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.parameter input,
.parameter select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.parameter button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ff0000;
  color: white;
  cursor: pointer;
}

.parameter button:hover {
  background-color: #cc0000;
}
